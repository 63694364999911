import { useContext } from "react";
import { AppContext } from "../../context/AppProvider";
import "./Header.scss";

const Header = () => {

  const { activeLink, setActiveLink } = useContext(AppContext);

  return (
    <header className="Header">
      <div className="px-3 w-full h-full mx-auto max-w-screen-lg relative flex flex-wrap sm:flex-nowrap justify-between">
        <div className="Header-Logo">
          <img alt="" src="/assets/images/logo.png" />
        </div>
        <div className="Header-Menu">
          <nav>
            <ul>
              <li onClick={() => {
                setActiveLink({link: "home", count: activeLink.count + 1});
              }}>Home</li>
              <li onClick={() => {
                setActiveLink({link: "menu", count: activeLink.count + 1});
              }}>Menu</li>
              <li onClick={() => {
                setActiveLink({link: "franchise", count: activeLink.count + 1});
              }}>Franchise</li>
              <li onClick={() => {
                setActiveLink({link: "contact", count: activeLink.count + 1});
              }}>Contact Us</li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
