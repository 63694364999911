import './App.scss';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import AppProvider from './context/AppProvider';
import Home from './pages/Home/Home';

function App() {

  return (
    <AppProvider>
      <div className="App">
        <Header />
        <Home />
        <Footer />
      </div>
    </AppProvider>
  );
}

export default App;
