import { createContext, useState } from "react";

export const AppContext = createContext({});

const AppProvider = ({ children }) => {

    const [activeLink, setActiveLink] = useState({link: "home", count: 0});

    return <AppContext.Provider value={{
        activeLink,
        setActiveLink
    }}>
        {children}
    </AppContext.Provider>
};

export default AppProvider;