import { useContext, useEffect } from "react";
import "./Home.scss";
import Parallax from "parallax-js";
import Banner from "../../components/Banner/Banner";
import { useForm } from "react-hook-form";
import { AppContext } from "../../context/AppProvider";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Home = () => {

  const { activeLink } = useContext(AppContext);

  useEffect(() => {
    if (activeLink && window) {
      const element = document.getElementById(`${activeLink.link}`);

      const y = element ? element.getBoundingClientRect().top + window.scrollY : 0;
      window.scroll({
        top: y - 120 <= 0 ? 0 : y - 120,
        behavior: 'smooth'
      });

    }
  }, [activeLink]);

  return (
    <div className="Home">
      <Illustration />
      <Brief />
      <Menu />
      <Banner className="Home-Banner" src="/assets/images/banner1.png" />
      <Partner />
      <Contact />
    </div>
  );
};

export default Home;

const Illustration = () => {
  useEffect(() => {
    const scene = document.getElementById("scene");
    const parallaxInstance = new Parallax(scene);
    parallaxInstance.friction(0.15, 0.15);
  }, []);

  return (
    <div id="scene" className="Home-Illustration relative">
      <div data-depth="0" className="Home-Illustration-Layer one">
        <img alt="" src="/assets/images/layers/sun.png" />
      </div>
      <div data-depth="-0.012" className="Home-Illustration-Layer two">
        <img alt="" src="/assets/images/layers/village.png" />
      </div>
      <div data-depth="0.017" className="Home-Illustration-Layer three">
        <img alt="" src="/assets/images/layers/cow.png" />
      </div>
      <div data-depth="0.023" className="Home-Illustration-Layer four">
        <img alt="" src="/assets/images/layers/sittingman.png" />
        <img alt="" src="/assets/images/layers/dog.png" />
      </div>
      <div data-depth="0.028" className="Home-Illustration-Layer five">
        <img alt="" src="/assets/images/layers/treeman.png" />
      </div>
      <div data-depth="0.033" className="Home-Illustration-Layer six">
        <img alt="" src="/assets/images/layers/woman.png" />
        <img alt="" src="/assets/images/layers/girl.png" />
        <img alt="" src="/assets/images/layers/boy.png" />
        <img alt="" src="/assets/images/layers/golisodaman.png" />
      </div>
      <div data-depth="0.045" className="Home-Illustration-Layer seven">
        <img alt="" src="/assets/images/layers/crow1.png" />
        <img alt="" src="/assets/images/layers/man.png" />
      </div>
      <div data-depth="0.05" className="Home-Illustration-Layer eight">
        <img alt="" src="/assets/images/layers/crow2.png" />
      </div>
      <div
        data-depth="0.15"
        className="Home-Illustration-Layer nine flex justify-center"
      >
        <div className="text-center">
          <h3 className="sm:text-3xl text-gray-800 font-semibold mb-2 mt-10">
            INDIAN TRADITIONAL
          </h3>
          <h1 className="text-5xl text-primary my-1 font-medium font-kitten-bold">Goli Drink</h1>
        </div>
      </div>
    </div>
  );
};

const Brief = () => {
  return (
    <div className="Home-Brief relative">
      <div className="grid grid-cols-6 w-full mx-auto relative max-w-screen-md">
        <div className="col-span-6 text-center">
          <h3 className="text-xl sm:text-3xl font-medium text-gray-800">
            MADE IN <span className="text-primary">INDIA</span>. MADE FOR{" "}
            <span className="text-primary">INDIA</span>.
          </h3>
        </div>
        <div className="col-span-6 sm:col-span-3 order-2 sm:order-1 my-8 px-3 flex justify-center sm:justify-start">
          <img
            alt=""
            className="w-3/4 h-full object-center relative object-contain"
            src="/assets/images/golisodaman.png"
          />
        </div>
        <div className="col-span-6 sm:col-span-3 order-1 sm:order-2 my-8 px-3 flex items-center">
          <p>
            When we think <span>'GOLISODA'</span>, we think basic, honest and
            uncomplicated. Those are also the qualities we look for in good
            beverages. These are also the values that inspired us for OLD SCHOOL
            DAYS, when we thought of making a <span>'GOLISODA'</span> that goes
            back to the fundamentals and brings people a taste that’s evocative
            of the original <span>'TRADITIONAL GOLISODA'</span>
          </p>
        </div>
      </div>
    </div>
  );
};

const Menu = () => {
  return (
    <div className="Home-Menu relative" id="menu">
      <div className="grid grid-cols-6 w-full mx-auto relative max-w-screen-md">
        <div className="col-span-6 text-center">
          <h3 className="text-3xl font-medium text-gray-800 mb-8">
            OUR <span className="text-primary">MENU</span>
          </h3>
        </div>
        <div className="col-span-3">
          <img alt=""
            className="w-full h-full object-center relative object-cover"
            src="/assets/images/soda1.png"
          />
        </div>
        <div className="Home-Menu-Listbox col-span-3 py-8 flex flex-col justify-center">
          <div className="Home-Menu-List">
            <h4>Plain Soda</h4>
          </div>
          <div className="Home-Menu-List">
            <h4>Lemon Soda</h4>
            <span>Lemon Salt</span>
            <span>Lemon Sweet</span>
          </div>
          <div className="Home-Menu-List">
            <h4>Special Soda</h4>
            <span>Sugandhi</span>
            <span>Ginger Lemon</span>
            <span>Ice Cream</span>
          </div>
        </div>

        <div className="Home-Menu-Listbox col-span-3 py-8 flex flex-col justify-center">
          <div className="Home-Menu-List">
            <h4>Rangu Soda</h4>
            <span>Grape</span>
            <span>Blueberry</span>
            <span>Strawberry</span>
            <span>Pineapple</span>
            <span>Orange</span>
            <span>Mango</span>
            <span>Red Guava</span>
            <span>Masala</span>
          </div>
        </div>
        <div className="col-span-3">
          <img alt=""
            className="w-full h-full object-center relative object-cover"
            src="/assets/images/soda2.png"
          />
        </div>

        <div className="col-span-3">
          <img alt=""
            className="w-full h-full object-center relative object-cover"
            src="/assets/images/soda3.png"
          />
        </div>
        <div className="Home-Menu-Listbox col-span-3 py-8 flex flex-col justify-center">
          <div className="Home-Menu-List">
            <h4>Mojito</h4>
            <span>Mint</span>
            <span>Chilli</span>
          </div>
          <div className="Home-Menu-List">
            <h4>Restaurant Specials</h4>
            <span>Kiwi</span>
            <span>Mojito</span>
            <span>Jeera</span>
          </div>
          <div className="Home-Menu-List flex justify-between">
            <span className="badge">New</span><h4>Pet Bottles Available</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

const Partner = () => {
  return (
    <div className="Home-Partner" id="franchise">
      <div className="w-full relative mx-auto max-w-screen-md">
        <h5>
          franchise
        </h5>

        <img alt=""
          className="relative w-3/4 h-auto mx-auto mt-3 mb-6"
          src="/assets/images/partner.png"
        />

        <h4>Great Reasons to Partner</h4>

        <div className="Home-Partner-Reasons">
          <div>
            <img alt="" src="/assets/images/reason1.png" />
            <span>Low Investment</span>
          </div>

          <div>
            <img alt="" src="/assets/images/reason7.png" />
            <span>Good Margins</span>
          </div>

          <div>
            <img alt="" src="/assets/images/reason3.png" />
            <span>No Royalty</span>
          </div>

          <div>
            <img alt="" src="/assets/images/reason5.png" />
            <span>20 varieties of Golisoda</span>
          </div>

          <div>
            <img alt="" src="/assets/images/reason2.png" />
            <span>Outlet Setup Support</span>
          </div>

          <div>
            <img alt="" src="/assets/images/reason4.png" />
            <span>Operational Support</span>
          </div>

          <div>
            <img alt="" src="/assets/images/reason6.png" />
            <span>Beverages Supplies</span>
          </div>
        </div>
      </div>
      <div className="w-full relative mx-auto max-w-screen-lg">
        <h5>Our Franchise Models</h5>

        <div className="Home-Partner-Models">
          <div>
            <img alt="" src="/assets/images/model1.png" />
            <h6>Budget Friendly Stall</h6>
            <span>6 x 3 ft</span>
          </div>
          <div>
            <img alt="" src="/assets/images/model2.png" />
            <h6>Container Stall</h6>
            <span>6 x 6 ft</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const Contact = () => {
  const emailRe =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();
  const onSubmit = async (data) => {
    const apiUrl = false ? 'http://localhost:5001/golisoda-45fdf/us-central1/sendMail' : 'https://us-central1-golisoda-45fdf.cloudfunctions.net/sendMail';
    const html = `<table style="font-size:16px;position:relative;margin: 50px auto" border="1">
      <tr>
        <td style="padding: 8px">Name</td>
        <td style="padding: 8px">${data.fullname}</td>
      </tr>
      <tr>
        <td style="padding: 8px">Email</td>
        <td style="padding: 8px">${data.email}</td>
      </tr>
      <tr>
        <td style="padding: 8px">Phone Number</td>
        <td style="padding: 8px">${data.phone}</td>
      </tr>
      <tr>
        <td style="padding: 8px">City</td>
        <td style="padding: 8px">${data.city}</td>
      </tr>
      <tr>
        <td style="padding: 8px">Location</td>
        <td style="padding: 8px">${data.location}</td>
      </tr>
    </table>`;
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fromEmail: '1920sgolisoda@gmail.com', fromName: data.fullname + '', toEmail: 'sales@1920sgolisoda.com', toName: 'Sales', html, subject: 'Online Enquiry' }),
      });

      const result = await response.json();
      if (result.status && result.status == 200) {
        reset();
        toast.success('Info Submitted! We will contact you soon.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      } else {
        toast.error('Oops! Retry later.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        console.log(result);
      }
    } catch (err) {
      console.log(err);
      toast.error('Oops! Retry later.', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }

  };
  return (
    <>
      <div className="Home-Contact">
        <div className="w-full mx-auto max-w-screen-md">
          <h5>for franchise info</h5>

          <div className="Home-Contact-Form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="formgroup required">
                <label htmlFor="fullname">Full Name</label>
                <input
                  name="fullname"
                  className="form-input"
                  {...register("fullname", { required: true })}
                />
                {errors.fullname && (
                  <span className="error">Full Name is required</span>
                )}
              </div>

              <div className="formgroup required">
                <label htmlFor="email">Email</label>
                <input
                  name="email"
                  className="form-input"
                  {...register("email", { required: true, pattern: emailRe })}
                />
                {errors.email && (
                  <>
                    {errors.email.type === "required" && (
                      <span className="error">Email is required</span>
                    )}
                    {errors.email.type === "pattern" && (
                      <span className="error">Email is invalid</span>
                    )}
                  </>
                )}
              </div>

              <div className="formgroup required">
                <label htmlFor="phone">Phone Number</label>
                <input
                  name="phone"
                  className="form-input"
                  {...register("phone", { required: true })}
                />
                {errors.phone && (
                  <span className="error">Phone is required</span>
                )}
              </div>

              <div className="formgroup required">
                <label htmlFor="city">City</label>
                <input
                  name="city"
                  className="form-input"
                  {...register("city", { required: true })}
                />
                {errors.city && <span className="error">City is required</span>}
              </div>

              <div className="formgroup required">
                <label htmlFor="location">Location</label>
                <input
                  name="location"
                  className="form-input"
                  {...register("location", { required: true })}
                />
                {errors.location && (
                  <span className="error">Location is required</span>
                )}
              </div>

              <button type="submit">Submit</button>
            </form>
          </div>

          <img alt=""
            className="mx-auto my-3 relative max-w-md w-full h-auto"
            src="/assets/images/banner2.png"
          />
        </div>
      </div>
      <div className="Home-Contact2" id="contact">
        <div className="w-full mx-auto max-w-screen-md">
          <h4>contact us</h4>
          <h5>interested in distributor or retail store?</h5>

          <div className="mx-auto relative flex flex-col items-center">
            <img alt="" className="w-1/5 h-auto my-5" src="/assets/images/logo.png" />
            <div className="text-sm text-center py-3">
              <span>Plot No: 689, Gokul Plots, Venkatramana Colony, Serilingampally,</span>
              <span className="block">Hyderabad - 500072, Telangana</span>
              <span className="block py-3 border-t-2 border-b-2 my-3"><a href="tel:+916301149674">+91 63011 49674</a></span>
              <span><a href="mailto:sales@1920sgolisoda.com">sales@1920sgolisoda.com</a></span>
            </div>
            <img alt="" className="w-1/3 h-auto my-5" src="/assets/images/sodacart.png" />
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
