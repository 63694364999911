import './Footer.scss';


const Footer = () => {
    return <footer className='Footer'>
            <div className='Footer-SocialLinks'>
                <a href="https://www.facebook.com/"><img alt="" src="/assets/images/facebook-icon.png" /></a>
                <a href="https://www.instagram.com/"><img alt="" src="/assets/images/instagram-icon.png" /></a>
            </div>
            <span>Copyright @2022 | 1920s Golisoda</span>
        </footer>
};

export default Footer;